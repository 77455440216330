export const clientNameFooter = "ASZENS";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "ASZENS PRATICAS INTEGRATIVAS DE BEM ESTAR LTDA";
export const nomeFornecedor = "ASZENS";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  // {
  //   type: "material-community",
  //   name: "instagram",
  //   link: "https://www.instagram.com",
  //   profile: "@blix.aplicativos"
  // },
  // {
  //   type: "material-community",
  //   name: "youtube",
  //   link: "https://www.youtube.com",
  // },

  // {
  //   type: "material-community",
  //   name: "whatsapp",
  //   link: "https://wa.me/message/KWKSZPRGPD2YI1",
  //   number: "(51) 98032-5196"
  // },
];

export const drawerClosedLinks = [
  // {
  //   title: "Fale Comigo",
  //   link: "https://instagram.com",
  // },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [];